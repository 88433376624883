<template>
  <div>
    <PageHeaderLayout>
      <el-card shadow="never" style="position:relative;">

        <div class="userInfo">
          <img :src="osmanthus_4" alt=''
               style="width: 100px;height: auto;position: absolute;left:0;bottom:-2px;display: block;z-index: 0;"/>
          <div class="img" style="z-index: 2" @click="dialogVisible = true">
            <img :src="userAvatar" alt='头像' style="width: 80px; height: 80px; display: block; line-height: 100px"/>
          </div>
          <div class="info">
            <span style="font-size: 20px">{{ getDate }}{{ userInfo.nickname || '-' }} ~ </span>
            <span style="font-size: 14px; color: #8a8f97; display: block; margin-top: 10px">今日{{
                todayWeather.dayweather
              }}
              （{{ todayWeather.daytemp }}°），晚上:{{ todayWeather.nightweather }}（{{ todayWeather.nighttemp }}°）</span>
            <br/>
          </div>

          <el-row class="right_box">
            <el-col :span="8" class="col">
              <div>
                <b> 所属部门 </b> <br/>
                <span v-for="(dept,key) in userInfo.depts" :key="dept.dpt_id">
                <el-tooltip :content="`${dept.name}(${dept.dpt_id})`" effect="light" placement="bottom">
                  <span> {{ dept.name || '' }} {{ key != userInfo.depts.length - 1 ? '/' : '' }}</span>
                </el-tooltip>
              </span>
              </div>
            </el-col>
            <el-col :span="8" class="col">
              <div>
                <b> 当前角色： </b> <br/>
                <span>
                {{ userInfo.role_name || '' }}
              </span>
              </div>
            </el-col>
            <el-col :span="8" class="col">
              <div>
                <b> 所属领导： </b>
                <br/>
                <span v-for="leader in leaders" :key="leader.id" style="margin-right: 5px">
                {{ leader.name || '' }} 『{{ leader.dept_name || '' }}』
                <span v-if="leader.is_leave == 'Y'" style="color: red">（已离职）</span>
              </span>
              </div>
            </el-col>
          </el-row>
          <!--          <img :src="lotus"-->
          <!--               style="width: 160px;height: 160px;position: absolute;right: -20px;display: block;z-index: 2"/>-->
          <!--          <img :src="lotusGif"-->
          <!--               style="width: 100px;height: auto;position: absolute;right:18px;display: block;z-index: 1;"/>-->
          <!--          <img :src="dragonfly" class="dragonfly"/>-->
          <!--          <img :src="maple" alt=''-->
          <!--               style="width: 120px;height: auto;position: absolute;right:0;top:-30px;display: block;z-index: 2;"/>-->
          <!--          <img :src="squirrel" alt=''-->
          <!--               style="width: 60px;height: auto;position: absolute;right:0;bottom:0;display: block;z-index: 1;"/>-->

          <!--          osmanthus_2-->
          <img :src="osmanthus_2" alt=''
               style="width: 140px;height: auto;position: absolute;right:0;bottom:0;display: block;z-index: 1;"/>

        </div>
      </el-card>
      <br/>
      <div class="main-page-content">
        <el-row style="margin-bottom: 20px" v-if="showNotice">
          <el-col :span="24">
            <el-card style="font-size: 20px">
              <b style="color: black">
                系统公告：
              </b>
              <b>{{ noticeData }}</b>
              <i @click="closeNotice" class="el-icon-close"
                 style="float: right;color: rgb(191, 12, 44);cursor: pointer"></i>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!--快捷导航-->
          <el-col :span="18">
            <MyMenus></MyMenus>
          </el-col>
          <!--我的代办-->
          <el-col :span="6">
            <user-message></user-message>
          </el-col>
          <!--          我的权限-->
          <el-col :span="9" style="height:320px">
            <user-permissions></user-permissions>
          </el-col>
          <el-col :span="9" style="height:320px">
            <custom-jump></custom-jump>
          </el-col>
          <el-col :span="6">
            <el-card class='box-card'>
              <div slot="header" class="clearfix">
                <b style="font-size: 16px">天气 &nbsp;</b>
              </div>
              <div class="weather">
                <video :poster="require(`@/assets/wether/${bgName}.png`)"
                       :src="require(`@/assets/wether/${bgName}.mp4`)" auto-rotate="false"
                       autoplay="true"
                       crossorigin="anonymous" height="100%"
                       loop="true" muted="true" playsinline="true" style="border-radius: 10px;"
                       t7-video-player-type="inline" webkit-playsinline="true" width="100%"
                       x5-playsinline="true"></video>
                <div class="weatherInfo">
                  <div>
                 <span>{{ todayWeather.daytemp }}<span style="font-size: 50px;top: -10px">°</span>
                   <span style="top: -10px">{{ todayWeather.dayweather }}转{{
                       todayWeather.nightweather
                     }}<span>{{ todayWeather.daywind }}风{{ todayWeather.daypower }}级</span></span></span>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>

          <!--        全部导航-->
          <el-col :span="18" style="margin-top: -150px">
            <el-card>
              <div slot="header" class="clearfix" style="position:sticky">
                <b style="font-size: 16px">全部菜单 &nbsp;</b>
              </div>
              <allMenus style="max-height: 500px;overflow-y: scroll"></allMenus>
            </el-card>

          </el-col>
          <!-- 基本资料修改-->
          <el-col :span="6" style="height:320px">
            <el-card class='box-card'>
              <div class='ripple_content ripple_success'
                   style='width: 100%;height: 100px;background-color: rgb(63, 178, 127);font-size: 20px'
                   @click="goToPage"> 前往-修改个人资料
              </div>
            </el-card>
          </el-col>
        </el-row>
        <!--        <el-row  style="margin-top: -20px">-->

        <!--        </el-row>-->
        <!--        <el-row>-->
        <!--          <el-col :span='24'>-->
        <!--            <el-link type='primary' @click='showMyMenu'><i class='el-icon-menu'></i>我的菜单-->
        <!--              <i :class="[showMenu?'el-icon-arrow-up':'el-icon-arrow-down']"></i>-->
        <!--            </el-link>-->
        <!--            <div style='padding: 10px;margin-bottom: 10px'>-->
        <!--            </div>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--        <el-row>-->
        <!--          <el-col :span='24'>-->
        <!--            <MyPermissionPanel />-->

        <!--          </el-col>-->
        <!--        </el-row>-->
      </div>
    </PageHeaderLayout>

    <el-dialog :visible.sync="dialogVisible" width="30%">
      <el-tabs v-model="activeName">
        <el-tab-pane label="随机头像" name="random">
          <div class="random">
            <avataaars id='avatarSvg'
                       style='width: 100px; height: 100px;'
                       :avatarStyle='avatar.avatarStyle'
                       :topType='avatar.topType'
                       :accessoriesType='avatar.accessoriesType'
                       :hairColor='avatar.hairColor'
                       :facialHairType='avatar.facialHairType'
                       :clotheType='avatar.clotheType'
                       :clotheColor='avatar.clotheColor'
                       :eyeType='avatar.eyeType'
                       :eyebrowType='avatar.eyebrowType'
                       :mouthType='avatar.mouthType'
                       :skinColor='avatar.skinColor'></avataaars>
            <br/>
            <el-button @click='randomAvatar'>随机施法</el-button>
          </div>
        </el-tab-pane>

        <el-tab-pane label="自定义上传" name="update">
          <ArtistAvatar :title='"请上传用户头像"' :img-url="userAvatar" @uploaded="handleUploadSuccess"/>

          <!--          <ApeUploader :limit="1" :upload-file-list="uploadFileList" uploadTip="请上传头像，最多上传1份。"-->
          <!--                       @handleUploadRemove="handleUploadRemove"-->
          <!--                       @handleUploadSuccess="handleUploadSuccess"></ApeUploader>-->
        </el-tab-pane>
      </el-tabs>


      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAvatar">确 定</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

import { mapGetters, mapMutations } from 'vuex'
import MyMenus from '@/pages/user/components/MyMenus'
import allMenus from '@/pages/user/components/allMenus.vue'
import userPermissions from '@/pages/user/components/userPermissions.vue'
import userMessage from '@/pages/user/components/userMessage.vue'
import UserMessage from '@/pages/user/components/userMessage.vue'
import ApeUploader from '@/components/ApeUploader'
import Avataaars from 'vuejs-avataaars'
import { generateRandomAvatar } from '@/utils/data/generateAvatar'
import saveSvg from 'save-svg-as-png'
import MyPermissionPanel from '@/pages/user/profile/MyPermissionPanel'
import axios from 'axios'
import CustomJump from '@/pages/user/components/CustomJump.vue'
import ArtistAvatar from '@/pages/artist/ArtistAvatar'
// import lotus from '@/assets/festival/summer/lotus.png'
// import lotusGif from '@/assets/festival/summer/lotus.gif'
// import dragonfly from '@/assets/festival/summer/dragonfly.gif'
// import maple from '@/assets/festival/autumn/maple.gif'
// import squirrel from '@/assets/festival/autumn/squirrel.gif'
import osmanthus_2 from '@/assets/festival/autumn/osmanthus_2.png'
import osmanthus_4 from '@/assets/festival/autumn/osmanthus_4.png'


export default {
  name: 'UserProfile',
  components: {
    CustomJump,
    UserMessage,
    MyPermissionPanel,
    MyMenus,
    allMenus,
    userPermissions,
    userMessage,
    PageHeaderLayout,
    ApeUploader,
    Avataaars,
    ArtistAvatar
  },
  data() {
    return {
      showMenu: false,
      showPermissions: false,
      dialogVisible: false,
      uploadFileList: [],
      avatar: {},
      userAvatar: '',
      uploadToken: {
        accessid: '',
        host: '',
        policy: '',
        signature: '',
        expire: 0,
        callback: '',
        dir: '',
        upload_save: '',
        filename: ''
      },
      uploadHeaders: {},
      uploadData: {},
      fileType: '',
      todayWeather: {},
      roleList: [],
      options: [],
      leaders: [], //我的上级领导
      bgName: 'rain',
      showNotice: false,
      noticeData: '暂无公告',
      activeName: 'random',
      // lotus: lotus,
      // lotusGif: lotusGif,
      // dragonfly: dragonfly
      // maple:maple,
      // squirrel:squirrel
      osmanthus_2: osmanthus_2,
      osmanthus_4: osmanthus_4

    }
  },
  created() {
    this.getUser()
    this.getWeather()
    this.getList()
    // this.getNotice()
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    // postGroup(){
    //   if(Array.isArray(this.user.posts)){
    //     return this.user.posts.reduce(function(total, currentValue, currentIndex, arr){
    //       return total + currentValue.post_name + " "
    //     }, "")
    //   }
    //   return ""
    // },
    roleGroup() {
      if (Array.isArray(this.user.roles)) {
        return this.user.roles.reduce(function(total, currentValue) {
          return total + currentValue.display_name + ' '
        }, '')
      }
      return ''
    },
    getDate() {
      let now = new Date()
      let hour = now.getHours()
      if (hour >= 5 && hour < 12) {
        return '早上好！'
      } else if (hour >= 12 && hour < 18) {
        return '下午好！'
      } else {
        return '晚上好！'
      }
    }
  },
  watch: {
    noticeData() {
      this.localNoticeData()
    },
    showNotice() {
      this.localNoticeData()
    }
  },
  methods: {
    ...mapMutations(['handleUserInfo']),
    localNoticeData() {
      let data = {
        data: this.noticeData,
        isShow: this.showNotice
      }
      localStorage.setItem('localNotice', JSON.stringify(data))
    },
    goToPage() {
      this.$router.push({ name: 'UserProfileEdit' })
    },
    showMyMenu() {
      this.showMenu = !this.showMenu
    },

    async getUser() {
      let { user } = await this.$api.getUserProfile()
      // console.log(user.avatar)

      this.user = user
      if (user.avatar) this.userAvatar = user.avatar
    },
    logout() {
      this.$confirm('确认退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.handleLogout()
          })
          .catch(() => {
          })
    },
    // 处理退出
    handleLogout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push({ path: `/login?redirect=${this.$route.fullPath}` })
        // location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    async updateAvatar() {
      let _this = this
      if (this.activeName === 'update') {
        let info = await this.$api.updateUserProfile({ avatar: this.userAvatar })
        if (info) {
          this.msgSuccess('成功!')
          this.dialogVisible = false

        }
        this.dialogVisible = false
        setTimeout(() => {
          let userInfo = JSON.parse(localStorage.getItem('user_info'))
          userInfo.avatar = this.userAvatar
          let newAvatar = { ...userInfo }
          this.handleUserInfo(newAvatar)
          localStorage.setItem('user_info', JSON.stringify(newAvatar))
          // location.reload()
        }, 1000)
      } else {
        setTimeout(function() {
          saveSvg.svgAsPngUri(document.getElementById('avatarSvg'), {}).then((uri) => {
            if (uri) {
              let file = _this.dataURLtoFile(uri) //图片base64转文件
              _this.fileUpload(file, 'temp.png') //上传到服务端
            } else {
              console.log('error')
            }
          })
        }, 300)
      }
    },
    // 图片删除回调
    handleUploadRemove() {
      this.userAvatar = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    },
    handleUploadSuccess(file) {
      this.userAvatar = file.full_path
    },
    randomAvatar() {
      // let _this = this
      const avatar = generateRandomAvatar()
      this.avatar = avatar

    },
    //base64转为图片
    dataURLtoFile(base64Img, filename = 'file') {
      let arr = base64Img.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    /**
     * 获取上传凭证
     */
    async getOssToken() {
      this.uploadToken = await this.$api.getOssToken()
    },
    async setOssParams() {
      await this.getOssToken()
      let fileKey = this.uploadToken.dir + this.uploadToken.filename
      if (this.fileType) {
        fileKey += this.fileType
      }
      if (this.uploadToken.upload_save == 'oss') {
        this.uploadData = {
          key: fileKey,
          policy: this.uploadToken.policy,
          OSSAccessKeyId: this.uploadToken.accessid,
          success_action_status: '200', //让服务端返回200,不然，默认会返回204
          callback: this.uploadToken.callback,
          signature: this.uploadToken.signature
        }
      }
      if (this.uploadToken.upload_save == 'local') {
        this.uploadHeaders = {
          'HYPERF-SESSION-ID': localStorage.getItem('HYPERF_SESSION_ID')
        }
        this.uploadData = {
          upload_path: this.uploadToken.dir
        }
      }
    },

    //文件上传到服务端
    async fileUpload(file, file_name) {
      let formData = new FormData()

      await this.setOssParams()
      let ossUrl = false
      if (this.uploadToken.upload_save == 'oss') {
        ossUrl = this.uploadToken.host
        formData.append('key', this.uploadData.key)
        formData.append('policy', this.uploadData.policy)
        formData.append('OSSAccessKeyId', this.uploadData.OSSAccessKeyId)
        formData.append('success_action_status', '200')
        formData.append('callback', this.uploadData.callback)
        formData.append('signature', this.uploadData.signature)
      }
      formData.append('file', file, file_name)
      this.$api
          .editorUploadFile(formData, ossUrl)
          .then(async (res) => {
            this.userAvatar = res[0].full_path
            let info = await this.$api.updateUserProfile({ avatar: res[0].full_path })
            if (info) {
              this.msgSuccess('成功!')
              let userInfo = JSON.parse(localStorage.getItem('user_info'))
              userInfo.avatar = res[0].full_path
              let newAvatar = { ...userInfo }
              this.handleUserInfo(newAvatar)
              localStorage.setItem('user_info', JSON.stringify(newAvatar))
              this.dialogVisible = false

              // location.reload()
            }
          })
          .catch(() => {
            this.msgError('上传失败')
            // this.$refs.cropper.clearCrop();
          })
    },
    //申请角色
    jumpToApplyPage() {
      this.$router.push({ name: 'apply_role' })
    },
    async getWeather() {
      // let weatherInfo = []
      let { data } = await axios.get('https://restapi.amap.com/v3/weather/weatherInfo', {
        params: {
          key: 'fa8bf7d4878d041a4e3bcb71aa79e891',
          city: 330100,
          extensions: 'all',
          output: 'JSON'
        }
      })
      if (data && Number(data.status) === 1) {
        // this.weatherInfo = data
        this.todayWeather = data.forecasts?.[0].casts?.[0]
        this.bgName = this.todayWeather.dayweather.search('雨') > -1 ? 'rain' : 'sun'
      }
    },
    switchWeek(data) {
      let d = ''
      switch (data) {
        case 1:
          d = '周一'
          break
        case 2:
          d = '周二'
          break
        case 3:
          d = '周三'
          break
        case 4:
          d = '周四'
          break
        case 5:
          d = '周五'
          break
        case 6:
          d = '周六'
          break
        case 7:
          d = '周日'
          break
      }
      return d
    },


    // 获取可申请角色列表
    async getList() {
      this.options = this.roleList
      let { leaders, roleList } = await this.$api.getMyApplyRoleList()
      this.options = roleList
      this.leaders = leaders
    },
    async getNotice() {
      let { data } = await this.$api.getMyApplyRoleList()
      //读取系统公告./如果和上次缓存的一样，并且isShow是false 那就不显示系统公告弹框
      let localNotice = JSON.parse(localStorage.getItem('localNotice'))
      if (localNotice && !(localNotice.isShow) && String(localNotice) === String(data)) {
        //如果一致则不显示 并且iSshow是false
        this.showNotice = false
      } else if (localNotice && !(localNotice.isShow)) {
        this.showNotice = false
      } else {
        this.noticeData = data
        this.showNotice = true
      }
    },
    closeNotice() {
      this.showNotice = false
    }
  }
}
</script>

<style lang="scss" scoped>
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "almmDk";
  font-weight: 400;
  src: url("//at.alicdn.com/wf/webfont/Z6Z6Ve9CViat/agXEvLxOkLgY.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/Z6Z6Ve9CViat/JzadOcsmDvJM.woff") format("woff");
  font-display: swap;
}

.weather {
  position: relative;
  height: 400px;

  > video {
    position: relative;
    left: 0;
    right: 0;
    display: block;
  }

  //position: relative;

  .weatherInfo {
    color: white;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    //border: 1px solid red;

    > div {
      width: 100%;
      font-family: almmDk, serif;
      text-align: center;
      height: 120px;
      position: absolute;
      /*写在子元素上,相对定位，相对父元素定位*/
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      > span {
        margin: 20px;
        font-size: 60px;
        position: relative;

        > span {
          font-size: 1.6rem;
          position: relative;
          font-family: almmDk, serif;

        }
      }
    }


  }
}

.list-group-striped > .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.list-group {
  padding-left: 0;
  list-style: none;
}

.list-group-item {
  border-bottom: 1px solid #e7eaec;
  border-top: 1px solid #e7eaec;
  margin-bottom: -1px;
  padding: 11px 0;
  font-size: 13px;
}

.text-center {
  text-align: center;
}

.box-card {
  margin-bottom: 20px;
}

.userInfo {
  width: 100%;
  height: 120px;
  display: flex;
  //line-height: 120px;
  .img {
    width: 80px;
    padding: 20px;
  }

  .info {
    padding: 36px;
    width: calc(100% - 580px); //line-height: 120px;
  }

  .right_box {
    width: 500px;
    height: 120px;
    z-index: 2;
    position: relative;
    right: 80px;

    .col {
      height: 100%;

      > div {
        //margin: 30px 0;
        margin: 30px auto;
        //text-align: center;
        width: 100%;
        //width: 200px !important;  //限制文本宽度
        word-wrap: break-word; //自动换行
        word-break: break-all; //自动换行（兼容字母）
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 3; //显示3行
        -webkit-box-orient: vertical;


        > b {
          font-size: 20px;
          text-align: left;
        }

        > span {
          font-size: 12px;
          display: inline-block;
          margin-top: 4px;
        }

        > span:first-child {
          margin-top: 10px;
        }
      }
    }
  }

}

.random {
  padding: 50px 20px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center;

  > button {
    margin-top: 10px;
  }
}

::v-deep .el-dialog__body {
  padding: 10px;
}

::v-deep .el-dialog__header {
  padding: 0;

}

.dragonfly {
  width: 40px;
  height: auto;
  position: absolute;
  right: 100px;
  display: block;
  z-index: 4;
  animation: moveRight 12s ease infinite normal;

}

@keyframes moveRight {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(80px, 80px);
  }
  100% {
    transform: translate(150px, 60px);
  }

}

</style>
